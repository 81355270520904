// This file replicates functionality from ./deprecated-columns in the same directory but modifies all columns to support DataGrid instead of BasicTable

import type { ColDef } from 'ag-grid-community';
import { isNil } from 'lodash';

import type { Theme } from 'venn-ui-kit';
import type { AnalysisRequest, RangeAnalysisResponse } from 'venn-api';

import type { BulkManageRow, BulkManageUpdateFn } from '../types';
import { dataSourceColDef } from './dataSourceColDef';
import { frequencyColDef } from './frequencyColDef';
import { startDateColDef, endDateColDef } from './dateColDefs';
import { getRangeColumn } from './analysisRangeColDef';
import { getProxyColDef } from './proxyColDef';

export { columnStyles } from './column-styles';

export const getBulkManagementColumns = (
  rangeAnalysis: RangeAnalysisResponse | undefined,
  onRowUpdate: BulkManageUpdateFn,
  theme: Theme,
  canEditProxies: boolean,
  hasFullHistory: boolean,
  rangeAnalysisRequest?: Partial<AnalysisRequest>,
): ColDef<BulkManageRow>[] => {
  const primaryIndex = rangeAnalysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'PRIMARY');
  const primaryRangeAnalysis = !isNil(primaryIndex) ? rangeAnalysis?.rangeAnalyses[primaryIndex] : null;

  return [
    getRangeColumn(theme, hasFullHistory, rangeAnalysis, primaryRangeAnalysis),
    startDateColDef,
    endDateColDef,
    frequencyColDef,
    dataSourceColDef,
    getProxyColDef(canEditProxies, onRowUpdate),
  ];
};
