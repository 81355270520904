import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import type { BulkProxyValidationState, FundToProxy } from 'venn-components';
import { CellLoader, GetColor, Icon, Tooltip } from 'venn-ui-kit';
import { assertExhaustive, capitalizeFirstLetter, Dates, getFormattedFrequency } from 'venn-utils';
import type { SelectedProxy } from './utils';
import { partition } from 'lodash';

interface ProxySummaryTableProps {
  investments: FundToProxy[];
  validationState: BulkProxyValidationState;
}

export const ProxySummaryTable = ({ investments, validationState }: ProxySummaryTableProps) => {
  switch (validationState.state) {
    case 'invalidSelection':
      return null;
    case 'loading':
      return (
        <Wrapper>
          <b>Investment / Proxy Summary</b>
          <Table hideExistingProxy>
            <Header selectedProxy={validationState.selectedProxy} />
            {investments.map((investment) => (
              <Row key={investment.id} investment={investment} status={{ status: 'loading' }} />
            ))}
          </Table>
        </Wrapper>
      );
    case 'ready':
      return (
        <Wrapper>
          <b>Investment / Proxy Summary</b>
          <ProxySummaryTableInternal
            investments={investments}
            investmentErrors={validationState.investmentInfo}
            selectedProxy={validationState.selectedProxy}
          />
        </Wrapper>
      );
    default:
      throw assertExhaustive(validationState);
  }
};

interface ProxySummaryTableInternalProps {
  investments: FundToProxy[];
  investmentErrors: Record<string, { disableSave: boolean; errorMessage: string | undefined } | null>;
  selectedProxy: SelectedProxy;
}

export const ProxySummaryTableInternal = ({
  investments,
  investmentErrors,
  selectedProxy,
}: ProxySummaryTableInternalProps) => {
  const [unableToProxyInvestments, ableToProxyInvestments] = partition(
    investments,
    (investment) => investmentErrors[investment.id]?.disableSave,
  );
  const investmentsWithProxies = investments.filter((investment) => !!investment.proxyId);

  return (
    <>
      <Table hideExistingProxy={investmentsWithProxies.length === 0}>
        {unableToProxyInvestments.length ? <Header unableToProxy selectedProxy={selectedProxy} /> : null}
        {unableToProxyInvestments.map((investment) => (
          <Row
            key={investment.id}
            investment={investment}
            status={{ status: 'unableToProxy', error: investmentErrors[investment.id]?.errorMessage ?? '' }}
          />
        ))}
        {ableToProxyInvestments.length ? <Header selectedProxy={selectedProxy} /> : null}
        {ableToProxyInvestments.map((investment) => (
          <Row key={investment.id} investment={investment} status={{ status: 'ableToProxy' }} />
        ))}
      </Table>
      {investmentsWithProxies.length ? (
        <Footer>
          {investmentsWithProxies.length} of the {investments.length} selected investments has an existing proxy.
          Applying the new proxy will override it.
        </Footer>
      ) : null}
    </>
  );
};

interface HeaderProps {
  unableToProxy?: boolean;
  selectedProxy: SelectedProxy;
}

const Header = ({ unableToProxy, selectedProxy }: HeaderProps) => {
  const { Colors } = useTheme();
  return (
    <>
      <div style={{ color: unableToProxy ? Colors.Error : Colors.Green }}>
        <b>
          {unableToProxy ? 'Unable' : 'Able'} to be proxied with {selectedProxy.name}
        </b>
      </div>
      <div>
        <b>Has existing proxy?</b>
      </div>
      <div>
        <b>Frequency</b>
      </div>
      <div>
        <b>Available dates</b>
      </div>
    </>
  );
};

interface RowProps {
  investment: FundToProxy;
  status:
    | {
        status: 'ableToProxy';
      }
    | {
        status: 'unableToProxy';
        error: string;
      }
    | {
        status: 'loading';
      };
}

const Row = ({ investment, status }: RowProps) => {
  return (
    <>
      <div style={{ display: 'inline-flex', gap: '4px' }}>
        <RowIcon status={status} />
        {investment.name}
      </div>
      <div>{investment.proxyId ? <Icon type="check" /> : null}</div>
      <div>{capitalizeFirstLetter(getFormattedFrequency(investment.unproxiedFrequency))}</div>
      <div>{`${Dates.toDDMMMYYYY(investment.unproxiedStartRange, investment.unproxiedFrequency)} - ${Dates.toDDMMMYYYY(investment.unproxiedEndRange, investment.unproxiedFrequency)}`}</div>
    </>
  );
};

const RowIcon = ({ status }: Pick<RowProps, 'status'>) => {
  const { Colors } = useTheme();
  switch (status.status) {
    case 'ableToProxy':
      return <Icon type="check" prefix="fal" style={{ color: Colors.Green }} />;
    case 'unableToProxy':
      return (
        <Tooltip content={status.error} usePortal>
          <Icon type="warning" style={{ color: Colors.Error }} />
        </Tooltip>
      );
    case 'loading':
      return <CellLoader />;
    default:
      throw assertExhaustive(status);
  }
};

const Wrapper = styled.div`
  margin-top: 32px;
  > div:nth-child(2) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  > div:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const Table = styled.div<{ hideExistingProxy: boolean }>`
  margin-top: 12px;
  display: grid;
  grid-template-columns: 1fr 150px 100px 175px;
  gap: 10px;
  padding: 8px 12px;
  background-color: ${GetColor.GreyScale.Grey10};

  ${({ hideExistingProxy }) =>
    hideExistingProxy &&
    css`
      > div:nth-child(4n + 2) {
        visibility: hidden;
      }
    `}
`;

const Footer = styled.div`
  font-size: 12px;
  line-height: 150%;
  background-color: ${GetColor.GreyScale.Grey40};
  padding: 4px 12px;
`;
