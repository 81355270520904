import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import {
  autoproxyFund,
  type ProxyTypeEnum,
  type LibrarySearchEntity,
  type ProxyMetrics,
  bulkSetProxy,
  type Fund,
} from 'venn-api';
import { GetColor, Icon, Notifications, NotificationType, PROXY_FAQ_HREF, Link as LinkStyle } from 'venn-ui-kit';
import ProxyTypeOptions from './ProxyTypeOptions';
import { getProxyTypeNoun, useQuery } from 'venn-utils';
import { getDisabledProxyMessage, type SelectedProxy } from './utils';
import { ProxyFundOptions } from './components';
import { useBulkProxyErrorMessage } from './useProxyErrorMessage';
import { useInvestmentsReturnsRanges, useProxyReturnsRange } from './useInvestmentsReturnsRanges';
import { compact, noop, sumBy, uniq } from 'lodash';
import { ExtrapolationToggle, useExtrapolateToggle } from './components/ExtrapolationToggle';
import { ProxySummaryTable } from './ProxySummaryTable';
import SidePanelOverlay from '../../../side-panel-overlay/SidePanelOverlay';

interface BulkProxyPickerProps {
  /**
   * Investments we need to find a proxy for
   */
  investments: Fund[];

  /**
   * Fires with the updated investment ids when any proxy has been changed.
   */
  onProxyChange: (fundId?: string[]) => void | Promise<void>;
}

export const BulkProxyPicker = ({ investments, onProxyChange }: BulkProxyPickerProps) => {
  const { onClose } = useContext(SidePanelOverlay.Context);
  const [selectedProxyType, setSelectedProxyType] = useState<ProxyTypeEnum | undefined>(
    () => getUniqueProxy(investments)?.proxyType,
  );
  const [selectedProxy, setSelectedProxy] = useState<SelectedProxy | undefined>(() => getUniqueProxy(investments));
  const [isProxiesUpdating, setIsProxiesUpdating] = useState(false);

  const extrapolationToggleProps = useExtrapolateToggle(investments, selectedProxyType);
  const { shouldExtrapolate } = extrapolationToggleProps;

  const { Colors } = useTheme();

  // TODO(VER-816, collini): refactor ProxyOptions so that we can compose the auto proxy table ourselves, and
  // remove the match% and returns chart columns when #investments > 1
  // TODO(VER-816, collini): refactor to util hook
  const bulkAutoProxyQuery = useQuery<{ results: ProxyMetrics[] }>(
    ['bulkAutoproxyFund', investments.map((investment) => investment.id)],
    async ({ signal }) => {
      const maxInvestmentsToQuery = 5;
      // TODO(VER-816, collini): AutoProxyOptions has an internal hardcoded limit of 5 for now, which we should make customizable.
      const maxResults = 5;

      const investmentsToQuery = investments.slice(0, maxInvestmentsToQuery);
      const responses = await Promise.all(
        investmentsToQuery.map((investment) => autoproxyFund(investment.id, shouldExtrapolate, signal)),
      );

      const results: ProxyMetrics[] = [];
      const totalResultsLength = sumBy(responses, (response) => response.content.results.length);
      let index = 0;
      while (results.length < Math.min(totalResultsLength, maxResults)) {
        // Take one result a time from each response, so that we get a wide sample without biasing towards any one investment
        const response = responses[index++ % responses.length];
        const result = response.content.results.shift();
        if (result) {
          results.push(result);
        }
      }

      return { results };
    },
  );

  const rawInvestmentReturnsRanges = useInvestmentsReturnsRanges(investments);

  const { data: rawProxyReturnsRange } = useProxyReturnsRange(selectedProxy?.id);

  const validationState = useBulkProxyErrorMessage({
    investments,
    rawInvestmentReturnsRanges,
    rawProxyReturnsRange,
    selectedProxy,
    selectedProxyType,
    extrapolate: shouldExtrapolate,
  });

  const onSaveProxy = useCallback(async () => {
    if (validationState.state !== 'ready' || !selectedProxyType) {
      return;
    }
    setIsProxiesUpdating(true);
    const investmentsWithoutErrors = investments.filter(
      (investment) => !validationState.investmentInfo[investment.id]?.disableSave,
    );
    try {
      await bulkSetProxy(
        investmentsWithoutErrors.map((investment) => ({
          proxyId: selectedProxy?.id,
          fundId: investment.id,
          proxyType: selectedProxyType,
          extrapolate: shouldExtrapolate,
          numLags: validationState.investmentInfo[investment.id]?.suggestedLags ?? 0,
        })),
      );
      onProxyChange(investmentsWithoutErrors.map(({ id }) => id));
      Notifications.notify(
        `${investmentsWithoutErrors.length} proxies updated successfully.`,
        NotificationType.SUCCESS,
      );
    } catch (e) {
      Notifications.notify('Failed to update proxies.', NotificationType.ERROR);
    } finally {
      setIsProxiesUpdating(false);
      onClose();
    }
  }, [investments, onProxyChange, onClose, validationState, selectedProxyType, shouldExtrapolate, selectedProxy]);

  const getDisabledSearchResultReason = useCallback(
    (result: LibrarySearchEntity) => {
      if (!selectedProxyType) {
        return undefined;
      }
      // Note that we don't check the backend for now, because it would be too slow to do the O(Investments * SearchResult) requests.
      const allReasons = compact(
        investments.map((investment, index) =>
          getDisabledProxyMessage(result, selectedProxyType, investment, rawInvestmentReturnsRanges?.[index] ?? null),
        ),
      );

      // If it works for ANY investments, allow it.
      if (allReasons.length < investments.length) {
        return undefined;
      }

      const hasOneUniqueMessage = uniq(allReasons).length === 1;
      if (hasOneUniqueMessage) {
        return allReasons[0];
      }

      return `This investment is not eligible for ${getProxyTypeNoun(selectedProxyType)} with any of your selected investments.`;
    },
    [investments, rawInvestmentReturnsRanges, selectedProxyType],
  );

  const proceedMessage = useMemo(() => {
    const validInvestments =
      validationState.state === 'ready'
        ? investments.filter((investment) => !validationState.investmentInfo[investment.id]?.disableSave).length
        : investments.length;
    if (validInvestments !== investments.length) {
      return `Apply proxy to ${validInvestments} of ${investments.length} investments`;
    }
    return 'Apply proxy';
  }, [validationState, investments]);

  return (
    <>
      <SidePanelOverlay.Header
        title="Proxy investments"
        subtitle={
          <>
            Proxies will be applied across your entire workspace.{' '}
            <LinkStyle>
              <a style={{ color: Colors.DarkBlue }} href={PROXY_FAQ_HREF} target="_blank" rel="noopener noreferrer">
                Learn more
              </a>
            </LinkStyle>
          </>
        }
      />
      <SidePanelOverlay.Body>
        <Body>
          <ProxyTypeOptions
            investments={investments}
            rawInvestmentRanges={rawInvestmentReturnsRanges}
            selectedProxyRange={rawProxyReturnsRange}
            selectedProxyType={selectedProxyType}
            onSelectProxyType={setSelectedProxyType}
            showInvalidTypes
          />
          <ExtrapolationToggle {...extrapolationToggleProps} />
          <SearchLabel>Search for an investment to use as a proxy:</SearchLabel>
        </Body>

        <ProxyFundOptions
          investmentId={investments[0].id}
          autoproxyResponse={{
            loading: bulkAutoProxyQuery.isLoading,
            result: bulkAutoProxyQuery.data ?? undefined,
          }}
          onSaveProxy={noop}
          disableAutofocus={false}
          getDisabledSearchResultReason={getDisabledSearchResultReason}
          initialProxy={selectedProxy ?? null}
          closePicker={noop}
          setSelectedProxy={(id, name) => setSelectedProxy({ id, name })}
          disableSave={validationState.state === 'loading' || !!validationState.error || isProxiesUpdating}
          footerDescription={
            validationState.state !== 'loading' &&
            validationState.error && (
              <Footer>
                <WarningIcon type="triangle-exclamation" /> {validationState.error}
              </Footer>
            )
          }
          hideFooter
          disableSearch={!selectedProxyType}
        />
        <ProxySummaryTable investments={investments} validationState={validationState} />
      </SidePanelOverlay.Body>
      <SidePanelOverlay.Footer
        cancelLabel={
          <>
            <Icon type="angle-left" /> Back
          </>
        }
        onCancel={onClose}
        onPrimaryClick={onSaveProxy}
        primaryLabel={proceedMessage}
        primaryDisabled={validationState.state === 'loading' || !!validationState.error || isProxiesUpdating}
      />
    </>
  );
};

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const WarningIcon = styled(Icon)`
  color: ${GetColor.Warning};
`;

const SearchLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
`;

const Footer = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

function getUniqueProxy(investments: Fund[]) {
  const id = investments[0].proxyId;
  const name = investments[0].proxyName;
  const proxyType = investments[0].proxyType;
  return id && name && investments.every((investment) => investment.proxyId === id)
    ? {
        id,
        name,
        proxyType,
      }
    : undefined;
}
