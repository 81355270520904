import React from 'react';
import type { ColDef } from 'ag-grid-community';
import type { BulkManageRow, BulkManageUpdateFn } from '../types';
import { BulkManageAction } from '../types';
import InvestmentActions from '../../investment-actions/InvestmentActions';
import { bulkManageSortFunction } from './bulkManageSortFunction';

export const getProxyColDef = (
  canEditProxies: boolean,
  onUpdate: BulkManageUpdateFn,
): ColDef<BulkManageRow, string | undefined> => {
  return {
    cellRenderer: ({ node, api }) => {
      if (node?.group || !node?.data) {
        return null;
      }

      const { data, rowIndex } = node;

      if (!data.investment) {
        return null;
      }
      const count = api.getDisplayedRowCount();
      const dropdownDirection = count > 10 && rowIndex > count / 2 ? 'up' : 'down';
      return (
        <InvestmentActions
          fund={data.investment}
          context="bulk-management"
          onFundDataUpdated={() => onUpdate(BulkManageAction.FUND_MODIFIED, data, data.investmentId)}
          dropdownVerticalDirection={dropdownDirection}
          isReadOnly={!canEditProxies}
          usePortal
        />
      );
    },
    headerName: 'Proxy',
    sortable: true,
    field: 'investment.proxyName',
    comparator: bulkManageSortFunction(),
    flex: 2,
  };
};
