import type { FC } from 'react';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { GetColor } from '../../style/color';

export interface CellLoaderProps {
  className?: string;
}

export const CellLoader: FC<React.PropsWithChildren<CellLoaderProps>> = React.memo(
  ({ className }) => (
    <Loader className={className}>
      <div />
      <div />
      <div />
      <div />
      <div />
    </Loader>
  ),
  (prevProps, nextProps) => prevProps.className === nextProps.className,
);
CellLoader.displayName = 'CellLoader';
export default CellLoader;

const stretchDelay = keyframes`
  0%,
  40%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
`;

const Loader = styled.div`
  width: 40px;
  height: 6px;
  font-size: 10px;
  display: inline-block;

  > div {
    margin: 0 1px;
    background-color: ${GetColor.Primary.Dark};
    height: 100%;
    width: 4px;
    display: inline-block;
    -webkit-animation: ${stretchDelay} 1s infinite ease;
    animation: ${stretchDelay} 1s infinite ease;
  }

  > div:nth-child(2) {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  > div:nth-child(3) {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  > div:nth-child(4) {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }

  > div:nth-child(5) {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
`;
