import React, { useContext, useMemo } from 'react';
import type { Fund, ProxyTypeEnum } from 'venn-api';
import { Button, GetColor, Icon, PROXY_FAQ_HREF } from 'venn-ui-kit';
import { BulkProxyPicker } from '../modals/pickers/proxy-picker/BulkProxyPicker';
import { useInvestmentsReturnsRanges } from '../modals/pickers/proxy-picker/useInvestmentsReturnsRanges';
import { useProxyTypeValidation } from '../modals/pickers/proxy-picker/useProxyTypeValidation';
import SidePanelOverlay from '../side-panel-overlay/SidePanelOverlay';
import { getProxyTypeNoun, plural, useModal } from 'venn-utils';
import styled from 'styled-components';
import { UserContext } from '../contexts';

interface ProxyMessageProps {
  selectableProxyTypes: ProxyTypeEnum[];
  canManageProxy: boolean;
}

interface BulkProxySectionProps {
  selectedFundIds: string[];
  funds: Fund[];
  onProxyChange: (fundIds?: string[]) => void | Promise<void>;
  onClearSelected: () => void;
}

const ProxyMessage = ({ selectableProxyTypes, canManageProxy }: ProxyMessageProps) => {
  if (!canManageProxy) {
    return (
      <>
        <WarningTextWrapper>
          <Icon type="warning" /> Setting proxies is not allowed
        </WarningTextWrapper>
        <SeparatorIcon type="pipe" />
        Contact your workspace administrator for more details.
      </>
    );
  }

  if (selectableProxyTypes.length === 0) {
    return (
      <>
        <WarningTextWrapper>
          <Icon type="warning" /> No proxy types available
        </WarningTextWrapper>
        <SeparatorIcon type="pipe" />
        <Link href={PROXY_FAQ_HREF} target="_blank" rel="noopener noreferrer">
          Learn more
        </Link>
      </>
    );
  }

  return (
    <>
      {plural(selectableProxyTypes.length, {
        1: 'Available proxy type: ',
        other: 'Available proxy types: ',
      })}
      <Selected>{selectableProxyTypes.map(getProxyTypeNoun).join(', ')}</Selected>
      <SeparatorIcon type="pipe" />
      <Link href={PROXY_FAQ_HREF} target="_blank" rel="noopener noreferrer">
        Learn more
      </Link>
    </>
  );
};

export const BulkProxySection = ({ selectedFundIds, funds, onProxyChange, onClearSelected }: BulkProxySectionProps) => {
  const [isOpen, onOpen, onClose] = useModal();
  const { hasPermission } = useContext(UserContext);

  const selectedFunds = useMemo(
    () => funds.filter((fund) => selectedFundIds.includes(fund.id)),
    [funds, selectedFundIds],
  );

  const rawInvestmentReturnsRanges = useInvestmentsReturnsRanges(selectedFunds);
  const proxyTypeValidationResults = useProxyTypeValidation(selectedFunds, rawInvestmentReturnsRanges, undefined);
  const selectableProxyTypes = Object.keys(proxyTypeValidationResults).filter(
    (x) => !proxyTypeValidationResults[x].hasSomeErrors,
  ) as (keyof typeof proxyTypeValidationResults)[];

  return (
    <StickyFooter>
      <DescriptionContainer>
        <TopDescription>
          {plural(selectedFunds.length, {
            1: '{{count}} investment selected',
            other: '{{count}} investments selected',
          })}
        </TopDescription>
        <BottomDescription>
          <ProxyMessage selectableProxyTypes={selectableProxyTypes} canManageProxy={hasPermission('MANAGE_PROXY')} />
        </BottomDescription>
      </DescriptionContainer>
      <RightContainer>
        <Button onClick={onClearSelected} refreshedStyling>
          Clear Selection
        </Button>
        <Button
          dominant
          onClick={onOpen}
          refreshedStyling
          disabled={selectedFunds.length === 0 || selectableProxyTypes.length === 0 || !hasPermission('MANAGE_PROXY')}
        >
          Select a Proxy
        </Button>
      </RightContainer>
      <SidePanelOverlay.Root
        width={1062}
        margin={0}
        noPadding
        side="right"
        isOpen={isOpen}
        handleClose={onClose}
        content={<BulkProxyPicker investments={selectedFunds} onProxyChange={onProxyChange} />}
        hideTopBackButton
      />
    </StickyFooter>
  );
};

const StickyFooter = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${GetColor.White};
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DescriptionContainer = styled.div`
  position: relative;
  flex: 1;

  .bulk-management-table-wrapper,
  & > div {
    width: 100%;
  }
`;

const TopDescription = styled.h4`
  font-weight: 700;
  margin: 0 0 2px;
`;

const BottomDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const SeparatorIcon = styled(Icon)`
  color: ${GetColor.MidGrey1};
`;

const Selected = styled.span`
  text-transform: capitalize;
`;

const Link = styled.a`
  font-weight: 700;
`;

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const WarningTextWrapper = styled.span`
  color: ${GetColor.Error};
`;
