import type { ColDef, ICellRendererParams, SortDirection } from 'ag-grid-community';
import type { CustomHeaderProps } from 'ag-grid-react';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import type { FrequencyEnum, RangeAnalysisResponse, SubjectRangeAnalysis } from 'venn-api';
import type { Theme } from 'venn-ui-kit';
import { CellLoader, ColorUtils, Icon, Tooltip, TooltipPosition, GetColor } from 'venn-ui-kit';
import { assertNotNil, itemHasNoReturns } from 'venn-utils';
import DataDateRange from '../../manage-data/DataDateRange';
import ProxiedInvestmentTooltipContent from '../ProxiedInvestmentTooltipContent';
import { atStartOfDay, getDataColor, getRangeLogic, getRowRangeLogic } from '../analysisPeriodUtils';
import type { BulkManageRow } from '../types';
import { bulkManageSortFunction } from './bulkManageSortFunction';
import { RangeCellClass } from './column-styles';
import { FillParentTooltip, emptyRangeTooltipCell } from './tooltip-cells';

type RangeRowValue = BulkManageRow;
type RangeColDef = ColDef<BulkManageRow, RangeRowValue>;

const analysisRangeComparator: RangeColDef['comparator'] = bulkManageSortFunction({
  valueGetter: (v) => (v?.endDate ?? 0) - (v?.startDate ?? 0),
});
const analysisRangeValueGetter = ({ data }) => data;

const analysisRangeAggFunc = ({ values, rowNode }) => ({
  ...assertNotNil(rowNode.data),
  rangeLoading: values.some((v) => v?.rangeLoading),
});

export const getRangeColumn = (
  theme: Theme,
  hasFullHistory: boolean,
  rangeAnalysis?: RangeAnalysisResponse,
  primaryRangeAnalysis?: SubjectRangeAnalysis | null,
): RangeColDef => {
  if (!rangeAnalysis) {
    return {
      headerComponentParams: {
        displayName: 'Available Analysis Period',
      },
      flex: 2,
    };
  }

  const { earliestStart, latestEnd, fullRange, overlap } = getRangeLogic(
    hasFullHistory,
    rangeAnalysis,
    primaryRangeAnalysis,
  );
  const overlapSection =
    overlap.percentageWidth > 0 ? (
      <OverlapBar widthPercentage={overlap.percentageWidth} leftMarginPercentage={overlap.percentageStart} />
    ) : null;

  return {
    headerComponent: AnalysisRangeHeader,
    headerComponentParams: {
      displayName: 'Available Analysis Period',
      range: overlap.length,
      rangeAnalysis,
    },
    flex: 2,
    minWidth: 270,
    cellClass: RangeCellClass,
    cellRenderer: ({ node, value }: ICellRendererParams<BulkManageRow, RangeRowValue>) => {
      if (!value || !node.data) {
        return null;
      }

      if (value.rangeLoading) {
        return <CellLoader />;
      }
      if (itemHasNoReturns(value.startDate, value.endDate)) {
        return emptyRangeTooltipCell;
      }
      if (value.isStrategy) {
        return overlapSection;
      }

      const zeroAllocation = value.allocation === 0;
      const { investmentColor, proxyColor, extrapolationColor } = getDataColor(theme, zeroAllocation);

      const frequency = assertNotNil(value.frequency);
      const startDate = assertNotNil(atStartOfDay(value.startDate));
      const endDate = assertNotNil(value.endDate);

      const { proxy, investment, extrapolation } = getRowRangeLogic({
        startDate,
        endDate,
        fullRange,
        earliestStart,
        latestEnd,
        proxyStartDate: atStartOfDay(value.proxyStartDate),
        proxyEndDate: value.proxyEndDate,
        extrapolateEndDate: value.extrapolateEndDate,
        extrapolateStartDate: atStartOfDay(value.extrapolateStartDate),
      });

      return (
        <>
          {overlapSection}
          <RangeBar
            widthPercentage={investment.percentageWidth}
            leftMarginPercentage={investment.percentageStart}
            zeroAllocation={zeroAllocation}
            frequency={frequency}
            color={investmentColor}
          >
            {proxy.percentageWidth > 0 && (
              <RangeBar
                widthPercentage={proxy.percentageWidth}
                leftMarginPercentage={proxy.percentageStart}
                color={proxyColor}
                zeroAllocation={zeroAllocation}
                frequency={frequency}
              />
            )}
            {extrapolation.percentageWidth > 0 && (
              <RangeBar
                widthPercentage={extrapolation.percentageWidth}
                leftMarginPercentage={extrapolation.percentageStart}
                color={extrapolationColor}
                zeroAllocation={zeroAllocation}
                frequency={frequency}
              />
            )}
            <FillParentTooltip
              position={TooltipPosition.Top}
              maxWidth={400}
              content={<ProxiedInvestmentTooltipContent data={node.data} />}
              usePortal
            />
          </RangeBar>
        </>
      );
    },
    comparator: analysisRangeComparator,
    valueGetter: analysisRangeValueGetter,
    aggFunc: analysisRangeAggFunc,
  };
};

export const overlapColor = ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.15);

export const rangeBarBackground = ({
  zeroAllocation,
  frequency,
  color,
}: {
  zeroAllocation: boolean;
  frequency?: FrequencyEnum;
  color: string;
}) => css`
  background: ${zeroAllocation
    ? `repeating-linear-gradient(-45deg, ${color}, ${color} 2px, transparent 2px, transparent 4px)`
    : frequency === 'QUARTERLY' || frequency === 'YEARLY'
      ? `repeating-linear-gradient(to right, ${color} 0, ${color} 4px, transparent 4px,transparent 8px)`
      : color};
`;

const RangeBar = styled.div<{
  widthPercentage: number;
  leftMarginPercentage: number;
  frequency?: FrequencyEnum;
  zeroAllocation: boolean;
  color: string;
}>`
  ${rangeBarBackground}

  ${({ widthPercentage, leftMarginPercentage }) => css`
    height: 8px;
    width: ${widthPercentage * 100}%;
    margin-left: ${leftMarginPercentage * 100 ?? 0}%;
    padding: 0;
    top: calc(50% - 4px);
    position: absolute;
  `}
`;

interface AnalysisRangeHeaderProps extends CustomHeaderProps {
  range: string;
  rangeAnalysis?: RangeAnalysisResponse;
}

const AnalysisRangeHeader = (props: AnalysisRangeHeaderProps) => {
  const [sortDir, setSortDir] = React.useState<SortDirection | undefined>(undefined);

  useEffect(() => {
    const updateSortDirection = () => {
      setSortDir(props.column.getSort());
    };
    props.column.addEventListener('sortChanged', updateSortDirection);

    return () => {
      props.column.removeEventListener('sortChanged', updateSortDirection);
    };
  }, [props.column]);

  return (
    <AnalysisRangeHeaderContainer
      onClick={(ev) => {
        props.progressSort(ev.shiftKey);
      }}
    >
      <span>{props.displayName}</span>
      <RangeContainer>
        {props.range}{' '}
        <Tooltip
          usePortal
          content={
            <div>
              Available Date Range:
              <br />
              <DataDateRange dataRangeInfo={props.rangeAnalysis} />
            </div>
          }
        >
          <Icon prefix="far" type="circle-info" />
        </Tooltip>{' '}
        <Icon
          style={{
            color: !sortDir ? 'transparent' : undefined,
          }}
          type={sortDir === 'asc' ? 'sort-up' : 'sort-down'}
        />
      </RangeContainer>
    </AnalysisRangeHeaderContainer>
  );
};

const AnalysisRangeHeaderContainer = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;

  font-weight: bold;
  &,
  :hover {
    color: unset;
  }
`;

const RangeContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;

  color: ${GetColor.HintGrey};
`;

const OverlapBar = styled.div<{
  widthPercentage: number;
  leftMarginPercentage: number;
}>`
  background-color: ${overlapColor};
  height: 100%;
  width: ${({ widthPercentage }) => widthPercentage * 100}%;
  margin-left: ${({ leftMarginPercentage }) => leftMarginPercentage * 100 ?? 0}%;
  padding: 0;
  top: 0;
  position: absolute;
`;
