import type { AppUser, Fund, ProxyTypeEnum, FrequencyEnum, ComputedInvestmentResidual } from 'venn-api';

export interface CreateUpdateMetadata {
  owner?: AppUser;
  created?: number;
  updatedBy?: AppUser;
  updated?: number;
}

export interface BulkManageRow extends CreateUpdateMetadata {
  selected?: boolean;
  name: string;
  strategyPath: string[];
  investment?: Fund;
  investmentId?: string;
  investmentLoading?: boolean;
  allocation?: number;
  dataSource?: string;
  rangeLoading?: boolean;
  startDate?: number;
  endDate?: number;
  frequency?: FrequencyEnum;
  proxyStartDate?: number;
  proxyEndDate?: number;
  proxyType?: ProxyTypeEnum;
  secondary?: boolean; // Is this row not part of the main data set? i.e. factor range
  isIntegration?: boolean;
  isBenchmark?: boolean;
  isStrategy?: boolean;
  isLive?: boolean;
  investmentForecast?: ComputedInvestmentResidual;
  investmentForecastLoading?: boolean;
  extrapolateStartDate?: number;
  extrapolateEndDate?: number;
}

export enum BulkManageAction {
  FUND_MODIFIED,
  INVESTMENT_FORECAST_MODIFIED,
}

export type BulkManageUpdateFn = (action: BulkManageAction, row: BulkManageRow, investmentId?: string) => Promise<void>;
