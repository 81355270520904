import React, { useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type { CellClickedEvent, ColDef, RowSelectionOptions, SelectionChangedEvent } from 'ag-grid-community';

import type { BulkManageRow } from './types';
import { DataGrid } from '../data-grid';
import { GetColor } from 'venn-ui-kit';
import type { AgGridReact } from 'ag-grid-react';
import { columnStyles } from './columns/column-styles';

const rowSelection: RowSelectionOptions<RowData> = {
  mode: 'multiRow',
  groupSelects: 'descendants',
  headerCheckbox: false,
  checkboxes: false,
  hideDisabledCheckboxes: true,
  isRowSelectable: ({ data }) => !!data && !!((data.isBenchmark && data.investmentId) || !data.secondary),
};

const defaultColDef: ColDef<RowData> = {
  suppressHeaderMenuButton: true,
  suppressMovable: true,
  editable: false,
  resizable: false,
};

const defaultIcons = {
  groupExpanded: '<i class="fa fa-caret-down"/>',
  groupContracted: '<i class="fa fa-caret-right"/>',
  check: '<i class="fa fa-caret-up"/>',
};

type RowData = BulkManageRow & { path: string[] };
const getDataPath = (data: RowData) => {
  return data.path;
};

const autoSizeStrategy = {
  type: 'fitCellContents',
} as const;

const GroupCellClass = 'group-cell';
const SecondaryCellClass = 'secondary-cell';

const autoGroupColumnDef: ColDef<RowData> = {
  headerName: 'Name',
  flex: 4,
  cellRenderer: 'agGroupCellRenderer',
  cellRendererParams: {
    checkbox: true,
    suppressCount: true,
    suppressEnterExpand: true,
    suppressDoubleClickExpand: true,
  },
  onCellClicked: ({ node }: CellClickedEvent<RowData>) => {
    node.setSelected(!node.isSelected());
  },
  cellClassRules: {
    [GroupCellClass]: ({ node }) => !!node?.group,
    [SecondaryCellClass]: ({ data }) => !!data?.secondary,
  },
};

interface BulkDataGridProps {
  gridRef?: React.MutableRefObject<AgGridReact | null>;
  rowData: BulkManageRow[];
  columnDefs: ColDef[];
  onSelectionChanged: (event: SelectionChangedEvent) => void;
}

const paginationOptions = [50, 100, 200, 400];

export const BulkDataGrid = React.memo(({ gridRef, rowData, columnDefs, onSelectionChanged }: BulkDataGridProps) => {
  const { Colors } = useContext(ThemeContext);

  const transformedRowData: RowData[] = useMemo(
    () =>
      rowData.map((row) => ({
        ...row,
        path: [...row.strategyPath, row.name],
      })),
    [rowData],
  );

  const defaultRowStyle = useMemo(
    () =>
      ({ node }) => {
        if (node.data?.secondary) {
          return { background: Colors.PaleGrey };
        }

        return undefined;
      },
    [Colors],
  );

  return (
    <GridContainer>
      <DataGrid<RowData>
        gridRef={gridRef}
        pagination={transformedRowData.length > 100}
        paginationPageSize={100}
        paginationPageSizeSelector={paginationOptions}
        rowSelection={rowSelection}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        rowData={transformedRowData}
        getRowStyle={defaultRowStyle}
        domLayout="autoHeight"
        icons={defaultIcons}
        rowHeight={37}
        headerHeight={25}
        onSelectionChanged={onSelectionChanged}
        suppressContextMenu
        treeData
        autoSizeStrategy={autoSizeStrategy}
        autoGroupColumnDef={autoGroupColumnDef}
        getDataPath={getDataPath}
        groupDefaultExpanded={-1} // -1 expands all groups by default
      />
    </GridContainer>
  );
});
BulkDataGrid.displayName = 'BulkDataGrid';

const GridContainer = styled.div`
  .ag-root-wrapper {
    font-family: ${(props) => props.theme.Typography.fontFamily};

    --ag-cell-horizontal-border: solid ${GetColor.Grey};
    --ag-header-background-color: ${GetColor.White};
    --ag-background-color: ${GetColor.White};
    --ag-odd-row-background-color: ${GetColor.White};

    --ag-row-group-indent-size: 20px;
    --ag-cell-widget-spacing: 4px;
    --ag-cell-horizontal-padding: 4px;
  }

  ${columnStyles}

  .${GroupCellClass} {
    font-weight: bold;
    color: ${GetColor.Black};
  }

  .${SecondaryCellClass} {
    font-style: italic;
    color: ${GetColor.GreyScale.Grey80};
  }

  .ag-checkbox-input-wrapper.ag-checked,
  .ag-checkbox-input-wrapper.ag-indeterminate {
    // Sensibly, the background-color is the color of the checkmark, and color is the color of the background.
    --ag-checkbox-background-color: ${GetColor.White};
    --ag-checkbox-checked-color: ${GetColor.DarkBlue};
    --ag-checkbox-indeterminate-color: ${GetColor.DarkBlue};
  }

  .ag-header {
    border-bottom-color: ${GetColor.Black};
    font-size: 12px;
  }

  .ag-ltr .ag-row-group-leaf-indent {
    margin-left: 0;
  }
`;
