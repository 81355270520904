/**
 * @fileoverview Query keys for use with react-query, to standardize the keys used across the application,
 *  and enable type-safe query key introspection such as in invalidation logic.
 *
 * Each Key is a tuple. The first element is a prefix, and the rest of the elements are the type-safe key arguments.
 *
 * Standardized keys enables benefits for request sharing, caching, and invalidation.
 */

import type { ProxyTypeEnum } from 'venn-api';
import type { StudioAnalysisRequest } from 'venn-state';
import type AnalysisSubject from '../analysis/AnalysisSubject';

export const STUDIO_ANALYSIS_KEY_PREFIX = 'studioAnalysis';
export type StudioAnalysisKey = ReturnType<typeof createStudioAnalysisKey>;
export const createStudioAnalysisKey = (...args: [analysisId: number, requests: StudioAnalysisRequest[]]) =>
  [STUDIO_ANALYSIS_KEY_PREFIX, ...args] as const;

export const FUND_RETURNS_RANGE_KEY_PREFIX = 'fundReturnsRange';
export type FundReturnsRangeKey = ReturnType<typeof createFundReturnsRangeKey>;
export const createFundReturnsRangeKey = (...args: [fundId: string]) =>
  [FUND_RETURNS_RANGE_KEY_PREFIX, ...args] as const;

export const GET_FUND_KEY_PREFIX = 'getFund';
export type GetFundKey = ReturnType<typeof createGetFundKey>;
export const createGetFundKey = (...args: [fundId: string]) => [GET_FUND_KEY_PREFIX, ...args] as const;

export const BULK_GET_FUND_KEY_PREFIX = 'getFundBulk';
export type BulkGetFundKey = ReturnType<typeof createBulkGetFundKey>;
export const createBulkGetFundKey = (...args: [fundIds: string[]]) => [BULK_GET_FUND_KEY_PREFIX, ...args] as const;

export const PROXY_OPTIONS_KEY_PREFIX = 'proxyOptions';
export type ProxyOptionsKey = ReturnType<typeof createProxyOptionsKey>;
export const createProxyOptionsKey = (...args: [fundId: string, proxyType: ProxyTypeEnum, numLags: number]) =>
  [PROXY_OPTIONS_KEY_PREFIX, ...args] as const;

export const PROXY_METADATA_KEY_PREFIX = 'proxyMetadata';
export type ProxyMetadataKey = ReturnType<typeof createProxyMetadataKey>;
export const createProxyMetadataKey = (...args: [subjectIds: string[]]) =>
  [PROXY_METADATA_KEY_PREFIX, ...args] as const;

export const PROXY_FEASIBILITY_KEY_PREFIX = 'proxyFeasibility';
export type ProxyFeasibilityKey = ReturnType<typeof createProxyFeasibilityKey>;
export const createProxyFeasibilityKey = (
  ...args: [
    fundId: string,
    proxyId: string | undefined,
    proxyType: ProxyTypeEnum | undefined,
    numLags: number | undefined,
    extrapolate: boolean | undefined,
  ]
) => [PROXY_FEASIBILITY_KEY_PREFIX, ...args] as const;

export const BULK_PROXY_FEASIBILITY_KEY_PREFIX = 'bulkProxyFeasibility';
export type BulkProxyFeasibilityKey = ReturnType<typeof createBulkProxyFeasibilityKey>;
export const createBulkProxyFeasibilityKey = (
  ...args: [
    proxyId: string | undefined,
    proxyType: ProxyTypeEnum | undefined,
    extrapolate: boolean | undefined,
    ...fundIds: string[],
  ]
) => [BULK_PROXY_FEASIBILITY_KEY_PREFIX, ...args] as const;

export const RANGE_ANALYSIS_KEY_PREFIX = 'rangeAnalysis';
export type RangeAnalysisKey = ReturnType<typeof createRangeAnalysisKey>;
export const createRangeAnalysisKey = (...args: [subject: AnalysisSubject | undefined]) =>
  [RANGE_ANALYSIS_KEY_PREFIX, ...args] as const;
