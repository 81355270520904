import React, { useRef, useCallback, useContext, useMemo, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type { OperationResult, Portfolio } from 'venn-api';
import { getFund, getSpecificPortfolioV3 } from 'venn-api';
import { Headline3, Subtitle1, TablePlaceholder } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import {
  createBulkGetFundKey,
  invalidate,
  logExceptionIntoSentry,
  logExhaustive,
  recursiveGetFunds,
  useFetchApi,
  useHasFF,
  useQuery,
} from 'venn-utils';
import EmptyState from '../empty-state/EmptyState';
import type { UseRangeAnalysisReturn } from '../hooks/useRangeAnalysis';
import { PlaceholderWrapper } from '../manage-data';
import { getBulkManagementColumns } from './columns/columns';
import type { BulkManageRow } from './types';
import { BulkManageAction } from './types';
import { BulkProxySection } from './BulkProxySection';
import { BulkDataGrid } from './BulkDataGrid';
import { PortfoliosContext } from '../contexts';
import type { SelectionChangedEvent } from 'ag-grid-community';
import { compact } from 'lodash';
import type { AgGridReact } from 'ag-grid-react';
import { getBulkManagementData } from './data';

interface BulkManagementTableProps {
  subject: AnalysisSubject;
  useRangeAnalysisReturn: UseRangeAnalysisReturn;
  onFundUpdated?: (fundId: string) => void;
  canEditForecasts: boolean;
  canEditProxies: boolean;
}

export const BulkManagementTable: React.FC<React.PropsWithChildren<BulkManagementTableProps>> = ({
  subject,
  useRangeAnalysisReturn,
  onFundUpdated,
  canEditProxies,
}) => {
  const gridRef = useRef<AgGridReact | null>(null);

  const { rangeAnalysis, refresh: refreshRange, analysisRequest: rangeAnalysisRequest } = useRangeAnalysisReturn;
  const fundIds = useMemo(() => {
    const allFunds = new Set(recursiveGetFunds(subject.portfolio));
    if (subject.fund?.id) {
      allFunds.add(subject.fund.id);
    }
    if (subject.activeBenchmarkType === 'investment' && typeof subject.activeBenchmarkId === 'string') {
      allFunds.add(subject.activeBenchmarkId);
    }
    if (subject.categoryGroup) {
      allFunds.add(subject.categoryGroup.categoryId);
    }
    return [...allFunds];
  }, [
    subject.portfolio,
    subject.fund?.id,
    subject.activeBenchmarkType,
    subject.activeBenchmarkId,
    subject.categoryGroup,
  ]);

  const { data: fundsData = [], isLoading: fundsLoading } = useQuery({
    staleTime: 60 * 1000,
    queryKey: createBulkGetFundKey(fundIds),
    queryFn: async () => {
      const response = await Promise.all(
        fundIds.map((id) =>
          getFund(id).catch((e) => {
            logExceptionIntoSentry(e);
            return undefined;
          }),
        ),
      );
      return compact(response).map((r) => r.content);
    },
  });

  const hasFullHistory = useHasFF('extend_full_history_ff');
  const theme = useContext(ThemeContext);

  const { masterPortfolio } = useContext(PortfoliosContext);
  const secondaryPortfolio = subject.secondaryPortfolioComparisonType === 'MASTER' ? masterPortfolio : undefined;
  const { result: benchmarkPortfolio } = useFetchApi(fetchBenchmarkPortfolio, subject.activeBenchmarkId);

  const rowData = useMemo(
    () =>
      getBulkManagementData(
        subject,
        fundsData,
        rangeAnalysis,
        rangeAnalysisRequest,
        secondaryPortfolio,
        benchmarkPortfolio,
      ),
    [subject, fundsData, rangeAnalysis, rangeAnalysisRequest, secondaryPortfolio, benchmarkPortfolio],
  );

  const handleRowUpdate = useCallback(
    async (action: BulkManageAction, row: BulkManageRow, _investmentId?: string) => {
      switch (action) {
        case BulkManageAction.FUND_MODIFIED:
          await refreshRange();
          if (row.investmentId) {
            onFundUpdated?.(row.investmentId);
            await invalidate('investment', row.investmentId);
          }
          break;
        case BulkManageAction.INVESTMENT_FORECAST_MODIFIED:
          break;
        default:
          logExhaustive(action);
      }
    },
    [refreshRange, onFundUpdated],
  );

  const [selectedFundIds, setSelectedFundIds] = useState<string[]>([]);

  const columns = useMemo(
    () =>
      getBulkManagementColumns(
        rangeAnalysis,
        handleRowUpdate,
        theme,
        canEditProxies,
        hasFullHistory,
        rangeAnalysisRequest,
      ),
    [rangeAnalysis, handleRowUpdate, theme, canEditProxies, hasFullHistory, rangeAnalysisRequest],
  );

  const onBulkProxyChange = useCallback(async (fundIds?: string[]) => {
    if (!fundIds) return;
    await invalidate('investments', fundIds);
  }, []);

  const onClearSelected = useCallback(() => {
    setSelectedFundIds([]);
    gridRef.current?.api.deselectAll();
  }, []);

  const onSelectionChanged = useCallback((event: SelectionChangedEvent<BulkManageRow, unknown>) => {
    const rows = event.api.getSelectedRows();
    setSelectedFundIds(compact(rows.map((row) => row.investmentId)));
  }, []);

  if (fundIds.length === 0 && !subject.fund) {
    return <EmptyState header="There are no investments in this portfolio." />;
  }

  if (fundsLoading) {
    return (
      <PlaceholderWrapper>
        <TablePlaceholder />
      </PlaceholderWrapper>
    );
  }

  return (
    <Wrapper>
      <HeaderContainer>
        <Headers>
          <Headline3>Portfolio Management</Headline3>
          <Subtitle1>
            Manage the investments in your portfolio by selecting one or more to apply proxies if needed.
          </Subtitle1>
        </Headers>
      </HeaderContainer>

      <BulkDataGrid gridRef={gridRef} rowData={rowData} columnDefs={columns} onSelectionChanged={onSelectionChanged} />

      {selectedFundIds.length > 0 && (
        <BulkProxySection
          selectedFundIds={selectedFundIds}
          funds={fundsData}
          onProxyChange={onBulkProxyChange}
          onClearSelected={onClearSelected}
        />
      )}
    </Wrapper>
  );
};

const fetchBenchmarkPortfolio = async (
  benchmarkId?: string | number,
): Promise<OperationResult<Portfolio | undefined>> => {
  // if it's not a portfolio id, just return a response with undefined content
  if (typeof benchmarkId !== 'number') {
    return {
      status: 200,
      headers: {},
      content: undefined,
    };
  }
  return getSpecificPortfolioV3(benchmarkId);
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  .bulk-management-table-wrapper,
  & > div {
    width: 100%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Headers = styled.div`
  flex: 1;
  margin-bottom: 10px;

  > h1 {
    margin-bottom: 0;
  }

  > h2 {
    margin-top: 4px;
    margin-bottom: 0;
  }
`;
