import type { ColDef } from 'ag-grid-community';
import type { BulkManageRow } from '../types';
import { formatDate } from '../../manage-data/utils';
import { bulkManageSortFunction } from './bulkManageSortFunction';

const DATE_COLUMN_MIN_WIDTH = 95;

export const startDateColDef: ColDef<BulkManageRow, number | undefined> = {
  headerName: 'Start Date',
  field: 'startDate',
  valueFormatter: ({ data, value }) => (data?.isStrategy ? '' : (formatDate(value) ?? '')),
  maxWidth: DATE_COLUMN_MIN_WIDTH,
  flex: 1,
  comparator: bulkManageSortFunction(),
  aggFunc: 'max',
};

export const endDateColDef: ColDef<BulkManageRow, number | undefined> = {
  headerName: 'End Date',
  field: 'endDate',
  valueFormatter: ({ data, value }) => (data?.isStrategy ? '' : (formatDate(value) ?? '')),
  maxWidth: DATE_COLUMN_MIN_WIDTH,
  flex: 1,
  comparator: bulkManageSortFunction(),
  aggFunc: 'min',
};
