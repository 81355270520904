import React, { useCallback, useMemo, useState } from 'react';
import type { AutoproxyResponse, ItemId, LibrarySearchEntity } from 'venn-api';
import type { SearchMenuItem } from '../../../../search-menu';
import { SearchMenuBar } from '../../../../search-menu';
import styled from 'styled-components';
import AutoproxyOptions from './AutoproxyOptions';
import { OptionsLoading } from './OptionsLoading';
import type { AnalysisSubject } from 'venn-utils';
import type { FundProxy } from '../../types';
import { SearchMenuColumn } from '../../../../search-menu/components/Columns';
import { StyledModalFooter } from './CategoryOptions';

interface ProxyFundOptionsInterface {
  /** Id of the investment being proxied */
  investmentId: string;
  /** FetchApiResult of autoproxyFund */
  autoproxyResponse: { loading: boolean; result?: AutoproxyResponse };
  /** Callback when a fund is selected as a proxy */
  onSaveProxy: (id: string, name: string) => void;

  /** By default, autofocus is on for the search bar. If this is true, autofocus is disabled */
  disableAutofocus: boolean;
  /** Function to determine whether the search result should be disabled or not */
  getDisabledSearchResultReason: (result: LibrarySearchEntity) => string | undefined;
  initialProxy: FundProxy | null;
  closePicker: () => void;
  setSelectedProxy: (id: string, name: string) => void;
  disableSave: boolean;
  footerDescription?: React.ReactNode;
  /** TODO(willw): Remove this temporary prop */
  hideFooter?: boolean;
  disableSearch?: boolean;
}

const SEARCH_COLUMNS = [
  SearchMenuColumn.CCY,
  SearchMenuColumn.IDENTIFIER,
  SearchMenuColumn.TYPE,
  SearchMenuColumn.AVAILABLE_DATES,
];

/**
 * Component that allows for search and selection of funds that can be used as proxies
 */
const ProxyFundOptions: React.FC<React.PropsWithChildren<ProxyFundOptionsInterface>> = ({
  autoproxyResponse,
  disableAutofocus,
  getDisabledSearchResultReason,
  investmentId,
  onSaveProxy,
  initialProxy,
  closePicker,
  setSelectedProxy,
  disableSave,
  footerDescription,
  hideFooter = false,
  disableSearch,
}) => {
  // we shouldn't show / allow saving categories in this component
  const initialProxyIsCategory = initialProxy?.proxyCategory;

  const [currentQuery, setCurrentQuery] = useState<string>(initialProxyIsCategory ? '' : (initialProxy?.name ?? ''));
  const [selectedSubject, setSelectedSubject] = useState<AnalysisSubject | undefined>(() =>
    initialProxyIsCategory || !initialProxy
      ? undefined
      : ({ id: initialProxy.proxyId, name: initialProxy.name } as AnalysisSubject),
  );
  const [currentValue, setCurrentValue] = useState<SearchMenuItem | undefined>(() =>
    selectedSubject
      ? ({
          category: 'investment',
          label: selectedSubject.name,
          value: { id: selectedSubject.id, name: selectedSubject?.name } as AnalysisSubject,
        } as SearchMenuItem)
      : undefined,
  );

  const { loading: autoproxyLoading, result: autoproxyResult } = autoproxyResponse;
  const setSelectedAnalysisSubject = useCallback(
    (id: string, name: string) => {
      setSelectedProxy(id, name);
      setCurrentValue({ category: 'investment', label: name, value: { id, name } as AnalysisSubject });
      setSelectedSubject({ id, name } as AnalysisSubject);
      setCurrentQuery(name);
    },
    [setSelectedProxy],
  );

  const showAutoProxyResults = !currentQuery.length || currentQuery === initialProxy?.name;
  const Options = useMemo(() => {
    if (autoproxyLoading) {
      return <OptionsLoading />;
    }

    if (showAutoProxyResults) {
      return <AutoproxyOptions autoproxyResponse={autoproxyResult} onSelectProxyFund={setSelectedAnalysisSubject} />;
    }

    return null;
  }, [autoproxyLoading, autoproxyResult, setSelectedAnalysisSubject, showAutoProxyResults]);

  const excludeFromSearch: ItemId[] = useMemo(() => [{ id: investmentId, type: 'FUND' }], [investmentId]);

  const getDisabledReason = useCallback(
    (option: SearchMenuItem) => {
      const reason = option.searchResult && getDisabledSearchResultReason(option.searchResult);
      return reason || '';
    },
    [getDisabledSearchResultReason],
  );

  const isOptionDisabled = useCallback(
    (option: SearchMenuItem) => {
      if (option.category === 'tag') {
        return false;
      }
      return !!option.searchResult && !!getDisabledSearchResultReason(option.searchResult);
    },
    [getDisabledSearchResultReason],
  );

  const onSelected = useCallback(
    (option: SearchMenuItem) => {
      if (!option.value) {
        return;
      }

      setSelectedProxy(option.value.id.toString(), option.value.name);
      setCurrentValue({
        category: 'investment',
        label: option.value.name,
        value: { id: option.value.id, name: option.value.name } as AnalysisSubject,
      });
      setSelectedSubject(option.value);
    },
    [setSelectedProxy],
  );

  return (
    <>
      <ContentContainer>
        <SearchMenuBar
          investmentsOnly
          autofocus={!disableAutofocus}
          proxyable
          onSelected={onSelected}
          onQueryChange={setCurrentQuery}
          excludedItems={excludeFromSearch}
          displayResultsAsBlock
          isOptionDisabled={isOptionDisabled}
          optionDisabledTooltipContent={getDisabledReason}
          columns={SEARCH_COLUMNS}
          showRecentlyAnalyzed={false}
          location="proxyPicker"
          value={currentValue}
          forceHideResults={showAutoProxyResults}
          isClearable
          clearQueryOnBlur
          closeOnSelect
          privateAssetSearchMode="PUBLIC_ONLY"
          disabled={disableSearch}
        />
        {Options}
      </ContentContainer>
      {hideFooter ? null : (
        <StyledModalFooter
          submitOnEnter
          primaryLabel="Save"
          primaryDisabled={disableSave || !selectedSubject}
          primaryClassName="proxy-primary-save-button"
          onPrimaryClick={() => selectedSubject && onSaveProxy(String(selectedSubject.id), selectedSubject.name)}
          onSecondaryClick={closePicker}
          secondaryLabel="Cancel"
          leftChildren={footerDescription}
        />
      )}
    </>
  );
};

export default ProxyFundOptions;

const ContentContainer = styled.div`
  padding: 8px 28px 16px 28px;
`;
