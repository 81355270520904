import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'venn-ui-kit';

export const FillParentTooltip = styled(Tooltip)`
  width: 100%;
  height: 100%;
  display: block;
`;

/** Simple cell with tooltip indicating content is empty due to no returns */
function getNoReturnsTooltippedCell(cellContent: React.ReactNode) {
  return (
    <FillParentTooltip usePortal maxWidth={240} content={<div>This item does not have a return stream.</div>}>
      {cellContent}
    </FillParentTooltip>
  );
}

/* Internal cell padding to trigger tooltips to show on no text */
const RangeBarTooltipTrigger = styled.div`
  height: 16px;
  width: 100%;
`;

export const emptyRangeTooltipCell = getNoReturnsTooltippedCell(<RangeBarTooltipTrigger />);
export const notApplicableTooltipCell = getNoReturnsTooltippedCell('N/A');
