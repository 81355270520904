import { isNil } from 'lodash';
import type { RangeAnalysisResponse, AnalysisRequest, Portfolio, SubjectRangeAnalysis, Fund } from 'venn-api';
import { getAppTitle } from 'venn-ui-kit';
import type { AnalysisSubject, WithStrategies } from 'venn-utils';
import { FundUtils, flattenNodeWithStrategyPath, getAnalysisLabels } from 'venn-utils';
import type { BulkManageRow, CreateUpdateMetadata } from './types';

export const getBulkManagementData = (
  subject: AnalysisSubject,
  funds?: Fund[],
  rangeAnalysis?: RangeAnalysisResponse,
  rangeAnalysisRequest?: Partial<AnalysisRequest>,
  secondaryPortfolio?: Portfolio,
  benchmarkPortfolio?: Portfolio,
): BulkManageRow[] => {
  const result: BulkManageRow[] = [];
  if ((!funds || !funds.length) && !subject.fund) {
    return result;
  }

  const fundIdToFund = new Map(funds?.map((f) => [f.id, f]));

  const primaryIndex = rangeAnalysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'PRIMARY');
  const primaryRangeAnalysis = !isNil(primaryIndex) ? rangeAnalysis?.rangeAnalyses[primaryIndex] : undefined;
  const rangeAnalyses = primaryRangeAnalysis?.rangeAnalyses ?? [];
  const rangeAnalysesByInvestmentId = new Map(rangeAnalyses.map((a) => [a.investmentId, a]));

  const flattenedPortfolioWithStrategyPaths = flattenNodeWithStrategyPath(subject.portfolio);
  const rangeLoading = isNil(rangeAnalysis);

  if (subject.fund) {
    result.push(
      mapFundToRow(subject.fund.name, [], subject.fund, primaryRangeAnalysis, rangeLoading, false, undefined),
    );
  }

  for (const portfolioEntry of flattenedPortfolioWithStrategyPaths) {
    if (!portfolioEntry.fund) {
      // If the node doesn't have a fund, it is either the root or a strategy.
      if (portfolioEntry.id !== subject.portfolio?.id && portfolioEntry.children.length) {
        result.push(mapStrategyToRow(portfolioEntry));
      }
      continue;
    }

    const fund = fundIdToFund.get(portfolioEntry.fund.id);
    if (!fund) {
      // hasn't loaded yet, so skip it for now
      continue;
    }

    result.push(
      mapFundToRow(
        fund.name,
        portfolioEntry.strategyPath,
        fund,
        rangeAnalysesByInvestmentId.get(fund.id),
        rangeLoading,
        false,
        portfolioEntry.allocation,
      ),
    );
  }

  const labels = getAnalysisLabels(subject.type, subject.secondaryLabel, subject.secondaryPortfolio?.updated);

  if (subject.hasBenchmark && labels.benchmark) {
    const benchmarkIndex = rangeAnalysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'BENCHMARK');
    const benchmarkRange = !isNil(benchmarkIndex) ? rangeAnalysis?.rangeAnalyses[benchmarkIndex] : undefined;
    const benchmarkFund = funds?.find((fund) => fund.id === subject.activeBenchmarkId);

    const name = benchmarkRange?.name ? `${labels.benchmark}: ${benchmarkRange.name}` : labels.benchmark;
    const row: BulkManageRow = benchmarkFund
      ? mapFundToRow(name, [], benchmarkFund, benchmarkRange, rangeLoading)
      : mapPortfolioToRow(name, benchmarkPortfolio, benchmarkRange, rangeLoading);
    row.isBenchmark = true;
    result.push(row);
  }

  if (subject.categoryGroup && labels.category) {
    const categoryIndex = rangeAnalysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'CATEGORY');
    const categoryRange = !isNil(categoryIndex) ? rangeAnalysis?.rangeAnalyses[categoryIndex] : undefined;
    const categoryFund = funds?.find((fund) => fund.id === subject.categoryGroup?.categoryId);

    const name = categoryRange?.name ? `${labels.category}: ${categoryRange.name}` : labels.category;

    result.push(mapFundToRow(name, [], categoryFund, categoryRange, rangeLoading));
  }

  if (subject.hasSecondarySubject && labels.comparison) {
    const secondaryIndex = rangeAnalysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'COMPARISON');
    const secondaryRange = !isNil(secondaryIndex) ? rangeAnalysis?.rangeAnalyses[secondaryIndex] : undefined;

    result.push(mapPortfolioToRow(labels.comparison, secondaryPortfolio, secondaryRange, rangeLoading));
  }

  if (rangeAnalysis?.factorRange) {
    const factorRange = rangeAnalysis.factorRange;
    const row: BulkManageRow = {
      name: 'Factors',
      strategyPath: [],
      startDate: factorRange.start,
      endDate: factorRange.end,
      frequency: factorRange.frequency,
      secondary: true,
      rangeLoading,
      dataSource: `${getAppTitle()}`,
    };
    result.push(row);
  }

  return result;
};

const getCreateUpdateMetadata = (subject?: Fund | Portfolio): CreateUpdateMetadata => ({
  created: subject?.created,
  updated: subject?.updated,
  owner: subject?.owner,
  updatedBy: subject?.updatedBy,
});

const mapStrategyToRow = (strategy: WithStrategies<Portfolio>): BulkManageRow => ({
  ...getCreateUpdateMetadata(strategy),
  name: strategy.name,
  strategyPath: strategy.strategyPath,
  startDate: strategy.periodStart,
  endDate: strategy.periodEnd,
  allocation: strategy.allocation,
  frequency: strategy.lowestFrequency,
  isStrategy: true,
});

const mapPortfolioToRow = (
  name: string,
  portfolio?: Portfolio,
  portfolioRange?: SubjectRangeAnalysis,
  rangeLoading?: boolean,
): BulkManageRow => ({
  ...getCreateUpdateMetadata(portfolio),
  name,
  strategyPath: [],
  startDate: portfolioRange?.start,
  endDate: portfolioRange?.end,
  frequency: portfolioRange?.frequency,
  rangeLoading,
  secondary: true,
});

const mapFundToRow = (
  name: string,
  strategyPath: string[],
  fund?: Fund,
  investmentRange?: SubjectRangeAnalysis,
  rangeLoading?: boolean,
  secondary = true,
  allocation?: number,
): BulkManageRow => ({
  ...getCreateUpdateMetadata(fund),
  name,
  strategyPath,
  investmentId: fund?.id,
  allocation,
  investment: fund,
  dataSource: fund?.dataSource ?? (fund?.userUploaded ? 'Upload' : `${getAppTitle()}`),
  isIntegration: FundUtils.isUserIntegration(fund?.investmentSource),
  isLive: fund?.live,
  proxyType: fund?.proxyType,
  startDate: investmentRange?.start,
  endDate: investmentRange?.end,
  proxyStartDate: investmentRange?.proxyStartDate ?? undefined,
  proxyEndDate: investmentRange?.proxyEndDate ?? undefined,
  extrapolateStartDate: investmentRange?.extrapolateStartDate ?? undefined,
  extrapolateEndDate: investmentRange?.extrapolateEndDate ?? undefined,
  frequency: investmentRange?.frequency,
  rangeLoading,
  secondary,
});
